import '../utils/log'
import React, { useEffect } from 'react'
import Head from 'next/head'
import Cookies from 'js-cookie'
import Router from 'next/router'
import { routeChangeHandler } from '@bees-web/nfa-interactive-global/utils'
import { routeInitialize } from '@bees-web/nfa-interactive-global/actions/page'
import { config } from '../config'
import '@bees-web/nfa-components-react/lib/themes/base.scss'
import { initialize } from '../segment'
import * as braze from '../utils/scripts/braze'
import { PageProps } from '../core/interfaces'
import { AppProps, NextWebVitalsMetric } from 'next/app'
import { clearPersistStorage } from '../utils/storage'
import { useOneTrust } from '../core/hooks'
import { transformMetricName } from '../utils/transformMetricName'
import store from '../core/store'

//localfonts
import '/public/fonts/global.css'
import { Provider } from 'react-redux'
import anonymizeProperties from '../segment/middlewares/anonymize'

const version = process.env.BUILD_BUILDID

if (!globalThis.segment) globalThis.segment = initialize(config)

function removeImpevaCookies(): void {
  // Remove Imperva cookies for non prod -1
  if (process.env.STAGE !== 'prod') {
    const cookieList = []
    Object.keys(Cookies.get()).forEach((cookie) => {
      if (cookie.includes('incap_ses')) {
        cookieList.push(cookie)
      }
    })
    for (let i = 0; i < cookieList.length - 1; i++) {
      Cookies.remove(cookieList[i])
    }
  }
}

function addNewRelicAttribute(key: string, value: any): void {
  const newRelicAvailable =
    process.env.NODE_ENV !== 'development' &&
    !!process.env.NEW_RELIC_BROWSER_LICENSE_KEY &&
    !!process.env.NEW_RELIC_APPLICATION_ID &&
    !!window.NREUM

  if (key && newRelicAvailable) {
    window.NREUM.setCustomAttribute(key, value)
  }
}

function getReferrer(ref: string | string[], origin: string): string {
  return ref ? `${origin}${ref}` : document.referrer
}

function getReferrerUrl(referrer: string): URL | null {
  return referrer && referrer !== '' ? new URL(referrer) : null
}

const ABIApp = ({
  Component,
  pageProps,
  router,
}: AppProps<PageProps>): React.JSX.Element => {
  const { dispatch } = store
  const { ref, referrer } = router.query
  const { locale, pathname, ajsUserId, applicationId } = (pageProps ||
    {}) as PageProps
  const [_language, country] = locale.split('_')
  globalThis.analytics = globalThis.segment[country]

  useOneTrust()

  useEffect(() => {
    removeImpevaCookies()
    clearPersistStorage(version)
    addNewRelicAttribute('beesApplication', applicationId)
    anonymizeProperties(country)

    if (referrer) {
      dispatch(routeInitialize(origin, pathname, referrer))
    } else {
      const { origin, pathname } = window.location
      const referrer = getReferrer(ref, origin)
      const referrerUrl = getReferrerUrl(referrer)
      dispatch(
        routeInitialize(
          origin,
          pathname,
          referrerUrl ? referrerUrl.pathname : ''
        )
      )
    }
  }, [])

  useEffect(() => routeChangeHandler(Router, store), [store])

  return (
    <div>
      <Head>
        {braze.initialize(
          ajsUserId,
          pathname,
          config[country].braze.key,
          config[country].braze.host
        )}
      </Head>
      <Provider store={store}>
        <div className="main">
          <Component {...pageProps} />
        </div>
      </Provider>
    </div>
  )
}

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  if (typeof window.newrelic === 'object') {
    window.newrelic.setCustomAttribute(
      transformMetricName(metric.name, metric.label),
      metric.value
    )
  }
}

export default ABIApp
